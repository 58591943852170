import { useMutation } from "@tanstack/react-query";

import api from "../../../common/coreApiClient";
import { useOperatorContext } from "../OperatorContext/OperatorContext";
import { queryClient } from "./api";
import { getPaymentDemandsQueryKey } from "./usePaymentDemandsQuery";

export const useCreatePaymentDemandMutation = () => {
  const { operatorId } = useOperatorContext();

  return useMutation({
    mutationKey: ["createPaymentDemand"],
    mutationFn: (variables) =>
      api.post("/v1/internal/payment_demands", undefined, {
        partner_id: variables.partnerId,
        currency: variables.currency,
        creator_id: operatorId,
        ...variables.data
      }),
    onSuccess: (_data, variables) => {
      queryClient.invalidateQueries(
        getPaymentDemandsQueryKey(variables.partnerId)
      );
    }
  });
};
