import { QueryClientProvider } from "@tanstack/react-query";
import React from "react";

import { MantineProvider } from "../../../common/MantineProvider";
import { SearchParamsProvider } from "../../../common/useSearchParams";
import { queryClient } from "../api/api";
import { OperatorContextProvider } from "../OperatorContext";
import { PartnersContextProvider } from "../PartnersContext";
import { Payments } from "../Payments";

export const Main = ({ finance, userId }) => {
  if (!finance) {
    return null;
  }

  return (
    <SearchParamsProvider>
      <QueryClientProvider client={queryClient}>
        <OperatorContextProvider operatorId={userId}>
          <PartnersContextProvider>
            <MantineProvider>
              <Payments />
            </MantineProvider>
          </PartnersContextProvider>
        </OperatorContextProvider>
      </QueryClientProvider>
    </SearchParamsProvider>
  );
};
