import classNames from "classnames";
import { Formik } from "formik";
import React, { useContext } from "react";

import { formatDate, formatTime } from "../../../../common/date";
import { Toggle } from "../../../../common/forms/Toggle";
import { RadioToggle } from "../components/RadioToggle";
import { HomeInfoContext } from "../HomeInfoContext";
import { HomeInfoForm } from "../HomeInfoForm";
import { useAirbnbSync } from "../useAirbnbSync";
import { useGuestySync } from "../useGuestySync";

export const ListingIntegration = () => {
  const { homeInfo, updateHomeInfo } = useContext(HomeInfoContext);

  const airbnb = useAirbnbSync();
  const guesty = useGuestySync();

  const live = homeInfo.property_attributes.property_live;

  return (
    <Formik
      onSubmit={updateHomeInfo}
      initialValues={{
        host_preferences_attributes: {
          airbnb_listed: homeInfo.host_preferences_attributes.airbnb_listed,
          multiplat_listed:
            homeInfo.host_preferences_attributes.multiplat_listed
        },
        property_attributes: {
          listing_ready: homeInfo.property_attributes.listing_ready,
          multiplat_sync_ready:
            homeInfo.property_attributes.multiplat_sync_ready
        }
      }}
    >
      {({ values, setFieldValue }) => (
        <HomeInfoForm sidebarName="Property">
          <h4 className="section-name">Integration</h4>
          <div className="alert alert-primary">
            Please note that these settings do NOT affect the synchronization of
            availability and pricing. Turning synchronization off will NOT
            unlist the listing unless the separate buttons below the toggle have
            been changed first.
          </div>
          <div className="border rounded p-3 my-3">
            <h4>Airbnb</h4>
            <div
              className={classNames("alert alert-primary", {
                "alert-danger": airbnb.syncErrors
              })}
            >
              {airbnb.syncedAt ? (
                <>
                  Last sync on {formatDate(new Date(airbnb.syncedAt))}{" "}
                  {formatTime(new Date(airbnb.syncedAt))}
                </>
              ) : (
                "Never synced yet"
              )}

              {airbnb.syncErrors && (
                <div className="mt-2">
                  <i className="fas fa-exclamation-circle sync-error-icon" />{" "}
                  {airbnb.syncErrors.message}
                </div>
              )}
            </div>
            {airbnb.hasRequiredFields ? (
              <div className="alert alert-success">
                All required information is present
              </div>
            ) : (
              <div className="alert alert-danger">
                Some of the required fields are missing
              </div>
            )}
            <Toggle
              id="airbnb_sync_enabled"
              label="Enable sync to Airbnb?"
              containerClass="my-3"
              checked={values.property_attributes.listing_ready}
              disabled={!airbnb.hasRequiredFields}
              onChange={(event) => {
                setFieldValue(
                  "property_attributes.listing_ready",
                  event.target.checked
                );
              }}
            />

            <RadioToggle
              name="host_preferences_attributes.airbnb_listed"
              label="Airbnb Listing Status"
              onLabel="Listed"
              onDescription="Guests can find this listing in search results and requests or book available dates."
              offLabel="Unlisted"
              offDescription="Guests can't book this property or find it in search results."
            />
          </div>

          <div className="border rounded p-3 my-3">
            <h4>Guesty</h4>
            {!guesty.active && (
              <div className="alert alert-danger">
                Guesty integration is not active
              </div>
            )}
            <div
              className={classNames("alert alert-primary", {
                "alert-danger": guesty.error
              })}
            >
              {guesty.syncedAt ? (
                <>
                  Last sync on {formatDate(new Date(guesty.syncedAt))}{" "}
                  {formatTime(new Date(guesty.syncedAt))}
                </>
              ) : (
                "Never synced yet"
              )}

              {guesty.error && (
                <div className="mt-2">
                  <i className="fas fa-exclamation-circle sync-error-icon" />{" "}
                  {guesty.error}
                </div>
              )}
            </div>
            {guesty.missingFields.length > 0 ? (
              <div className="alert alert-danger">
                Those fields are required for Guesty sync:
                <ul className="mb-0">
                  {guesty.missingFields.map((incompleteSection) => (
                    <li key={incompleteSection.section}>
                      <strong>{incompleteSection.section}:</strong>{" "}
                      {incompleteSection.fields.join(", ")}
                    </li>
                  ))}
                </ul>
              </div>
            ) : (
              <div className="alert alert-success">
                All required information is present
              </div>
            )}

            {!live && (
              <div className="alert alert-danger">
                <strong>Property is not live</strong>
              </div>
            )}
            <Toggle
              id="guesty_sync_enabled"
              label="Enable sync to Guesty?"
              containerClass="my-3"
              checked={values.property_attributes.multiplat_sync_ready}
              disabled={
                !guesty.active || guesty.missingFields.length > 0 || !live
              }
              onChange={(event) => {
                setFieldValue(
                  "property_attributes.multiplat_sync_ready",
                  event.target.checked
                );
              }}
            />

            <RadioToggle
              name="host_preferences_attributes.multiplat_listed"
              label="Guesty Listing Status"
              onLabel="Listed"
              onDescription="Guests can find this listing in search results and requests or book available dates."
              offLabel="Unlisted"
              offDescription="Guests can't book this property or find it in search results."
            />
          </div>
        </HomeInfoForm>
      )}
    </Formik>
  );
};
