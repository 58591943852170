import { useQuery } from "@tanstack/react-query";

import api from "../../../common/coreApiClient";

export const usePartnersQuery = () => {
  return useQuery({
    queryKey: ["partners"],
    queryFn: () => api.get("/v1/internal/partners"),
    refetchOnWindowFocus: false,
    refetchOnReconnect: false
  });
};
