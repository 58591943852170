import { Select, Skeleton, Stack, Text } from "@mantine/core";
import React, { forwardRef } from "react";

import { usePartnersContext } from "../PartnersContext";

const PartnerSelectItem = forwardRef(({ label, country, ...props }, ref) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <div spacing="xs" ref={ref} {...props}>
    <Text size="md">{label}</Text>
    {country && (
      <Text size="xs" c="dimmed">
        {country}
      </Text>
    )}
  </div>
));

export const PartnerSelect = () => {
  const {
    partners,
    loading,
    selectedPartnerId,
    setSelectedPartnerId
  } = usePartnersContext();

  if (loading) {
    return (
      <Stack my="md">
        <Skeleton height={36} />
      </Stack>
    );
  }

  const data = partners.map((partner) => ({
    value: partner.id,
    label: partner.name,
    country: partner.localities
      // Display unique country codes
      .reduce(
        (acc, locality) =>
          acc.includes(locality.country_code)
            ? acc
            : [...acc, locality.country_code],
        []
      )
      .join(", "),
    group: partner.is_active ? "Active" : "Inactive"
  }));

  return (
    <Stack my="md">
      <Select
        data={data}
        itemComponent={PartnerSelectItem}
        placeholder="Select partner"
        value={selectedPartnerId}
        onChange={setSelectedPartnerId}
        maxDropdownHeight={500}
        searchable
      />
    </Stack>
  );
};
