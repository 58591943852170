import React, { createContext } from "react";

export const OperatorContext = createContext({ operatorId: null });

export const useOperatorContext = () => React.useContext(OperatorContext);

export const OperatorContextProvider = ({ operatorId, children }) => {
  return (
    <OperatorContext.Provider value={{ operatorId }}>
      {children}
    </OperatorContext.Provider>
  );
};
