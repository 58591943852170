import React, { createContext, useCallback, useEffect, useMemo } from "react";

import { useSearchParams } from "../../../common/useSearchParams";
import { usePartnersQuery } from "../api";

export const PartnersContext = createContext({ partners: [], loading: true });

export const usePartnersContext = () => React.useContext(PartnersContext);

export const PartnersContextProvider = ({ children }) => {
  const { data: partners = [], isLoading } = usePartnersQuery();

  const { get, set, remove } = useSearchParams();

  const queryPartnerId = useMemo(() => get("partner_id"), [get]);

  const selectedPartnerId = useMemo(() => {
    const partnerId = parseInt(queryPartnerId, 10);

    return Number.isInteger(partnerId) ? partnerId : null;
  }, [queryPartnerId]);

  const selectedPartner = useMemo(() => {
    return partners.find((partner) => partner.id === selectedPartnerId);
  }, [partners, selectedPartnerId]);

  const setSelectedPartnerId = useCallback(
    (id) => {
      if (typeof id !== "number" && !id) {
        remove("partner_id");
        return;
      }

      set("partner_id", id);
    },
    [remove, set]
  );

  useEffect(() => {
    if (isLoading || !queryPartnerId) {
      return;
    }

    if (!selectedPartner) {
      setSelectedPartnerId(null);
    }
  }, [isLoading, queryPartnerId, selectedPartner, setSelectedPartnerId]);

  return (
    <PartnersContext.Provider
      value={{
        partners,
        loading: isLoading,
        selectedPartner,
        selectedPartnerId,
        setSelectedPartnerId
      }}
    >
      {children}
    </PartnersContext.Provider>
  );
};
