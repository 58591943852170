import { useQuery } from "@tanstack/react-query";

import api from "../../../common/coreApiClient";

export const getPaymentDemandsQueryKey = (partnerId) =>
  `payment_demands_${partnerId}`;

export const usePaymentDemandsQuery = ({ partnerId }) => {
  return useQuery({
    queryKey: ["payment_demands", getPaymentDemandsQueryKey(partnerId)],
    queryFn: () => api.get(`/v1/internal/payment_demands/${partnerId}`),
    enabled: typeof partnerId === "number"
  });
};
