import {
  Button,
  Group,
  NumberInput,
  SegmentedControl,
  Stack,
  Textarea
} from "@mantine/core";
import { DatePickerInput } from "@mantine/dates";
import { endOfMonth, format, startOfDay, startOfMonth, sub } from "date-fns";
import { Field, Form, Formik } from "formik";
import React from "react";
import * as yup from "yup";

import { CustomField } from "../../../common/CustomField";
import { getPortal } from "../../../common/MantineProvider";

export const PaymentDemandForm = ({
  onSubmit,
  initialValues,
  currency,
  action,
  loading
}) => {
  const handleSubmit = ({ due_date, ...values }) => {
    const dueDate = new Date(due_date);

    onSubmit({ ...values, due_date: format(dueDate, "yyyy-MM-dd") });
  };
  return (
    <Formik
      onSubmit={handleSubmit}
      initialValues={
        initialValues ?? {
          destination: "houst",
          due_date: startOfDay(endOfMonth(new Date())),
          description: ""
        }
      }
      validationSchema={yup.object({
        amount: yup.number().required("Amount is required").min(0.01)
      })}
      validateOnChange={false}
      validateOnBlur={false}
      disabled={loading}
    >
      <Form>
        <Stack maw={600}>
          <CustomField
            name="destination"
            as={SegmentedControl}
            onChange={(value, set) => {
              set(value);
            }}
            data={[
              { label: "Charge", value: "houst" },
              { label: "Pay", value: "partner" }
            ]}
            disabled={action === "edit"}
          />

          <Group grow align="flex-start">
            <CustomField
              name="amount"
              as={NumberInput}
              label="Amount"
              rightSection={
                action === "edit" ? initialValues.currency : currency
              }
              rightSectionWidth={50}
              precision={2}
              onChange={(value, set) => {
                set(value);
              }}
              w={180}
              maw="100%"
              hideControls
            />

            <CustomField
              name="due_date"
              as={DatePickerInput}
              label="Due date"
              placeholder="Select due date"
              maxLevel="month"
              minDate={sub(startOfMonth(new Date()), { months: 1 })}
              popoverProps={{
                withinPortal: true,
                portalProps: { target: getPortal() }
              }}
              w={180}
              maw="100%"
            />
          </Group>

          <Field name="description" as={Textarea} label="Description" />

          <Button type="submit" loading={loading}>
            {action === "edit" ? "Save" : "Add"}
          </Button>
        </Stack>
      </Form>
    </Formik>
  );
};
