import { useMutation } from "@tanstack/react-query";

import api from "../../../common/coreApiClient";
import { useOperatorContext } from "../OperatorContext";
import { queryClient } from "./api";
import { getPaymentDemandsQueryKey } from "./usePaymentDemandsQuery";

export const useUpdatePaymentDemandMutation = () => {
  const { operatorId } = useOperatorContext();
  return useMutation({
    mutationFn: (variables) =>
      api.patch(
        `/v1/internal/payment_demands/${variables.demandId}`,
        undefined,
        {
          ...variables.data,
          updater_id: operatorId
        }
      ),
    onSuccess: (_data, variables) => {
      queryClient.invalidateQueries(
        getPaymentDemandsQueryKey(variables.data.partner_id)
      );
    }
  });
};
