import { Button, Group, Modal, Stack } from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import React from "react";

import { getPortal } from "../../../common/MantineProvider";
import { usePaymentDemandsQuery, useCreatePaymentDemandMutation } from "../api";
import { usePartnersContext } from "../PartnersContext";
import { PaymentDemandForm } from "../PaymentDemandForm";
import PaymentsTable from "../PaymentsTable";

export const PartnerView = () => {
  const { selectedPartner } = usePartnersContext();
  const { data: paymentDemands, isLoading } = usePaymentDemandsQuery({
    partnerId: selectedPartner?.id
  });

  const {
    mutateAsync: createPaymentDemand,
    isLoading: isPaymentDemandCreating
  } = useCreatePaymentDemandMutation();

  const [opened, { open, close }] = useDisclosure();

  if (!selectedPartner) {
    return null;
  }

  if (isLoading) {
    return (
      <Stack my="md">
        <PaymentsTable loading />
      </Stack>
    );
  }

  return (
    <>
      <Stack my="md">
        <Group>
          <Button onClick={open}>
            <i className="fa fa-plus" />
          </Button>
        </Group>

        <PaymentsTable data={paymentDemands} />
      </Stack>

      <Modal
        opened={opened}
        onClose={close}
        title="New payment"
        target={getPortal()}
        size="lg"
      >
        <PaymentDemandForm
          onSubmit={async (data) => {
            await createPaymentDemand({
              data,
              currency: selectedPartner?.localities?.[0].currency,
              partnerId: selectedPartner?.id
            });
            close();
          }}
          action="new"
          currency={selectedPartner?.localities?.[0].currency}
          loading={isPaymentDemandCreating}
        />
      </Modal>
    </>
  );
};
