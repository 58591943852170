import React, { createContext, useContext, useState } from "react";

const query = new URLSearchParams(window.location.search);

const SearchParamsContext = createContext({
  get: (key) => query.get(key),
  set: () => {},
  remove: () => {},
  searchParams: query
});

export const useSearchParams = () => useContext(SearchParamsContext);

export const SearchParamsProvider = ({ children }) => {
  const [searchParams, setSearchParams] = useState(query);

  const get = (key) => searchParams.get(key);
  const set = (key, value) => {
    const updatedSearchParams = new URLSearchParams(searchParams);
    updatedSearchParams.set(key, value);
    setSearchParams(updatedSearchParams);
    window.history.replaceState(
      {},
      "",
      `${window.location.pathname}?${updatedSearchParams.toString()}`
    );
  };
  const remove = (key) => {
    const updatedSearchParams = new URLSearchParams(searchParams);
    updatedSearchParams.delete(key);
    setSearchParams(updatedSearchParams);
    window.history.replaceState(
      {},
      "",
      `${window.location.pathname}${
        updatedSearchParams.size > 0 ? updatedSearchParams.toString() : ""
      }`
    );
  };

  return (
    <SearchParamsContext.Provider value={{ get, set, remove, searchParams }}>
      {children}
    </SearchParamsContext.Provider>
  );
};
