import { ActionIcon, Modal, ScrollArea, Skeleton, Table } from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import { format } from "date-fns";
import React, { useMemo } from "react";

import { getPortal } from "../../../common/MantineProvider";
import { useUpdatePaymentDemandMutation } from "../api";
import { PaymentDemandForm } from "../PaymentDemandForm";

const destinationMap = {
  houst: "Charge",
  partner: "Pay",
  default: "Unknown"
};

const toLocalFromUTC = (dateString) => {
  const date = new Date(dateString);
  return new Date(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate());
};

export const PaymentsTable = ({ data, loading }) => {
  const [opened, { open, close }] = useDisclosure();
  const [selectedDemandId, setSelectedDemandId] = React.useState(null);
  const { mutateAsync, isLoading } = useUpdatePaymentDemandMutation();

  const currentDemand = useMemo(() => {
    const found = data?.find((demand) => demand.id === selectedDemandId);

    if (!found) {
      return {};
    }

    return {
      ...found,
      due_date: toLocalFromUTC(found.due_date)
    };
  }, [data, selectedDemandId]);

  return (
    <>
      <ScrollArea>
        <Table miw={600}>
          <thead>
            <tr>
              <th style={{ width: 100 }}>Type</th>
              <th style={{ width: 100 }}>Amount</th>
              <th style={{ width: 100 }}>Currency</th>
              <th style={{ width: 120 }}>Due date</th>
              <th>Description</th>
              <th style={{ width: 50 }} />
            </tr>
          </thead>
          {loading && (
            <tbody>
              {Array.from({ length: 4 }).map((_value, index) => (
                <tr key={index}>
                  {Array.from({ length: 6 }).map((_value, index) => (
                    <td key={index}>
                      <Skeleton height={16} />
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          )}
          {data && data.length > 0 && (
            <tbody>
              {data.map((transaction) => (
                <tr key={transaction.id}>
                  <td>
                    {destinationMap[transaction.destination] ??
                      destinationMap.default}
                  </td>
                  <td>{Number(transaction.amount).toFixed(2)}</td>
                  <td>{transaction.currency}</td>
                  <td>
                    {format(
                      toLocalFromUTC(transaction.due_date),
                      "dd MMM yyyy"
                    )}
                  </td>
                  <td>{transaction.description}</td>
                  <td>
                    <ActionIcon
                      variant="light"
                      size="sm"
                      onClick={() => {
                        setSelectedDemandId(transaction.id);
                        open();
                      }}
                    >
                      <i className="fa fa-pen" />
                    </ActionIcon>
                  </td>
                </tr>
              ))}
            </tbody>
          )}
        </Table>
      </ScrollArea>

      <Modal
        opened={opened}
        onClose={close}
        title="Change payment demand"
        target={getPortal()}
        size="lg"
      >
        <PaymentDemandForm
          onSubmit={async (values) => {
            await mutateAsync({
              demandId: selectedDemandId,
              data: values
            });
            close();
          }}
          loading={isLoading}
          initialValues={currentDemand}
          action="edit"
        />
      </Modal>
    </>
  );
};
