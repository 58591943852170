import { Formik } from "formik";
import React, { useState, useContext, useEffect, useMemo } from "react";
import { Link } from "react-router-dom";
import * as yup from "yup";

import { SelectOptions } from "../../../../common/SelectOptions";
import * as api from "../api";
import { Fieldset } from "../components/Fieldset";
import { FormGroup } from "../components/FormGroup";
import { LocationFields } from "../components/LocationFields";
import { HomeInfoContext } from "../HomeInfoContext";
import { HomeInfoForm } from "../HomeInfoForm";

const UNIT_TYPE_OPTIONS = [
  "home",
  "flat",
  "house",
  "studio_apartment",
  "terraced_house",
  "apartment",
  "hotel",
  "villa",
  "bungalow"
];

const TAX_TYPES = {
  FLAT_PER_GUEST: "flat_per_guest",
  FLAT_PER_NIGHT: "flat_per_night",
  FLAT_PER_GUEST_PER_NIGHT: "flat_per_guest_per_night"
};

const TAX_COLLECTION_CATEGORY_OPTIONS = {
  furnished_tourism: "Furnished tourist accommodation",
  tourist_residence: "Tourist residence"
};

const unitFloorSuffix = (unit_floor) => {
  if (unit_floor === "" || unit_floor > 0) return undefined;

  return () => (
    <span>{(unit_floor === -1 && "Lower Ground") || "Ground Floor"}</span>
  );
};

export const PropertyLocation = () => {
  const { homeInfo, updateHomeInfo } = useContext(HomeInfoContext);
  const [unitTypeOption, setUnitTypeOption] = useState(() =>
    UNIT_TYPE_OPTIONS.includes(homeInfo.unit_type)
      ? homeInfo.unit_type
      : "other"
  );
  const [safetyAmenities, setSafetyAmenities] = useState([]);

  const isParis = homeInfo?.locality?.name === "Paris";

  const parisValidation = {
    is: () => isParis,
    then: (schema) => schema.required("This field is required for Paris")
  };

  const validationSchema = yup.object().shape({
    property_attributes: yup.object().shape({
      street: yup.string().required("Property street is required"),
      city: yup.string().required("Property city is required"),
      postcode: yup.string().required("Property postcode is required"),
      country: yup.string().required("Property country is required")
    }),
    tax_collection_category: yup.string().when([], parisValidation),
    tax_collection_classification: yup.string(),
    registration_number: yup.string().when([], parisValidation),
    residence_type: yup.string().when([], parisValidation),
    business_tax_id: yup.string().when("registration_number", {
      is: (registration_number) =>
        registration_number && homeInfo?.locality?.name === "Lisbon",
      then: (schema) =>
        schema.required(
          "This field is required for Lisbon properties with a registration number"
        )
    }),
    unit_floor: yup.number().when("number_of_floors", {
      is: (number_of_floors) => number_of_floors >= 0,
      then: (schema) =>
        schema.max(
          yup.ref("number_of_floors"),
          "Unit floor cannot be greater than number of floors"
        )
    })
  });

  const handleSelectUnitTypeOption = (event, setFieldValue) => {
    const { value } = event.target;

    setUnitTypeOption(value);
    setFieldValue("unit_type", value);
  };

  const usesChekin = homeInfo?.locality?.name === "Barcelona";

  useEffect(() => {
    if (homeInfo?.locality?.name === "Lisbon") {
      api.fetchAmenityTypes("safety").then((res) => {
        if (res) {
          setSafetyAmenities(res.rows);
        }
      });
    }
  }, [homeInfo?.locality?.name]);

  const hasRequiredSafetyAmenities = useMemo(() => {
    if (homeInfo.locality.name !== "Lisbon") {
      return true;
    }
    const fireExtinguisherId = safetyAmenities.find(
      (el) => el.key === "fire_extinguisher"
    )?.id;

    const firstAidKitId = safetyAmenities.find(
      (el) => el.key === "first_aid_kit"
    )?.id;

    const requiredAmenities = homeInfo?.home_info_amenities_attributes?.safety?.filter(
      (el) => {
        if (el.deleted_at) {
          return false;
        }
        return (
          el.home_info_amenity_type_id === fireExtinguisherId ||
          el.home_info_amenity_type_id === firstAidKitId
        );
      }
    );

    return requiredAmenities.length === 2;
  }, [
    homeInfo.locality.name,
    homeInfo?.home_info_amenities_attributes?.safety,
    safetyAmenities
  ]);

  return (
    <Formik
      initialValues={{
        ...homeInfo,
        tourist_tax_amount: homeInfo.tourist_tax_amount || 0
      }}
      enableReinitialize
      validationSchema={validationSchema}
      validateOnMount
      onSubmit={updateHomeInfo}
    >
      {({ setFieldValue, values }) => (
        <HomeInfoForm sidebarName="Property">
          <h4 className="section-name">General information</h4>

          <Fieldset label="Property type">
            <div className="form-group">
              <div className="input-group">
                <select
                  name="_unit_type_option"
                  className="form-control"
                  value={unitTypeOption}
                  onChange={(e) => handleSelectUnitTypeOption(e, setFieldValue)}
                >
                  <SelectOptions options={[...UNIT_TYPE_OPTIONS, "other"]} />
                </select>
              </div>
            </div>

            {unitTypeOption === "other" && (
              <FormGroup name="unit_type" label="Other property type" />
            )}
          </Fieldset>

          <Fieldset label="Address">
            <FormGroup
              name="property_attributes.street"
              label="Street"
              visibleToGuests
              airbnbSync
              visibleToCleaners
            />

            <FormGroup
              name="property_attributes.city"
              label="City"
              visibleToGuests
              airbnbSync
              visibleToCleaners
            />

            <FormGroup
              name="property_attributes.postcode"
              label="Postcode"
              visibleToGuests
              airbnbSync
              visibleToCleaners
            />

            <FormGroup
              name="property_attributes.country"
              label="Country"
              component="select"
              visibleToGuests
              airbnbSync
            >
              <SelectOptions
                includeBlank
                options={homeInfo.property_country_options}
              />
            </FormGroup>

            <FormGroup
              className="w-50"
              name="number_of_floors"
              label="Number of floors"
              type="number"
              min="1"
              airbnbSync
            />

            <FormGroup
              className="w-50"
              name="unit_floor"
              label="Unit floor"
              type="number"
              min="-1"
              visibleToGuests
              renderAppend={unitFloorSuffix(values.unit_floor)}
              airbnbSync
            />

            <FormGroup
              name="property_attributes.building_name"
              label="Building name"
              visibleToGuests
            />
          </Fieldset>

          <Fieldset label="Registration number">
            <FormGroup
              name="registration_number"
              instructions="Some localities require hosts to obtain a registration number for short lets (e.g. Dublin, Sydney, eventually Edinburgh)"
              disabled={
                !values.registration_number && !hasRequiredSafetyAmenities
              }
            />
            {!values.registration_number && !hasRequiredSafetyAmenities && (
              <>
                <p>
                  In order to fill out this field in Lisbon, the property must
                  have those amenities turned on:
                </p>
                <ul>
                  <li>- Fire extinguisher</li>
                  <li>- First aid kit</li>
                </ul>
                <Link to="/amenities-safety" className="btn btn-primary">
                  Navigate to Safety Amenities
                </Link>
              </>
            )}
          </Fieldset>

          <Fieldset label="Tax ID number">
            <FormGroup name="business_tax_id" />
          </Fieldset>

          <Fieldset label="Tourist tax">
            <FormGroup
              name="tourist_tax_amount"
              label="Price"
              type="number"
              airbnbSync
              description={usesChekin ? "Chekin is in use" : ""}
              disabled={usesChekin}
            />
            <FormGroup
              name="tourist_tax_amount_type"
              label="Tax type"
              component="select"
              airbnbSync
              description={usesChekin ? "Chekin is in use" : ""}
              disabled={usesChekin}
            >
              <SelectOptions
                includeBlank
                options={[
                  [TAX_TYPES.FLAT_PER_GUEST, "Per guest"],
                  [TAX_TYPES.FLAT_PER_NIGHT, "Per night"],
                  [TAX_TYPES.FLAT_PER_GUEST_PER_NIGHT, "Per guest per night"]
                ]}
              />
            </FormGroup>
          </Fieldset>

          <Fieldset label="Listing category">
            <FormGroup
              name="tax_collection_category"
              label="Tax collection category"
              component="select"
              description="This is required for Paris only"
              airbnbSync
            >
              <SelectOptions
                includeBlank
                options={Object.entries(TAX_COLLECTION_CATEGORY_OPTIONS)}
              />
            </FormGroup>

            {isParis && values.tax_collection_category && (
              <FormGroup
                name="tax_collection_classification"
                label={`What kind of ${
                  TAX_COLLECTION_CATEGORY_OPTIONS[
                    values.tax_collection_category
                  ]
                }`}
                component="select"
                description="This is required for Paris only"
                airbnbSync
              >
                <SelectOptions
                  includeBlank={false}
                  options={[
                    ["", "Unclassified"],
                    [5, "5-star"],
                    [4, "4-star"],
                    [3, "3-star"],
                    [2, "2-star"],
                    [1, "1-star"]
                  ]}
                />
              </FormGroup>
            )}

            <FormGroup
              name="residence_type"
              label="Residence type"
              component="select"
              description="This is required for Paris only"
              airbnbSync
              instructions={
                <>
                  <p>
                    <strong>Primary residence</strong>
                    <br />
                    Your primary residence is the place you call home for most
                    of the year - at least 8 months to be exact (unless
                    exceptions apply)
                  </p>
                  <p>
                    <strong>Secondary residence</strong>
                    <br />A holiday home or a pied-à-terre
                  </p>
                  <p>
                    <strong>Non-residential</strong>
                    <br />
                    For example part of a hotel or B&B
                  </p>
                </>
              }
            >
              <SelectOptions
                includeBlank
                options={[
                  ["primary_residence"],
                  ["secondary_residence"],
                  ["non_residential", "Non-residential"]
                ]}
              />
            </FormGroup>
          </Fieldset>

          <Fieldset label="Property location">
            <LocationFields
              name={{
                lat: "home_info_location_attributes.property_lat",
                lng: "home_info_location_attributes.property_lng"
              }}
            />

            <FormGroup
              name="home_info_location_attributes.property_location_notes"
              label="Property location instructions"
              component="textarea"
              rows={5}
              visibleToGuests
            />
          </Fieldset>

          <Fieldset label="Nearby">
            <FormGroup
              name="home_info_location_attributes.nearest_station"
              label="Nearest station"
              visibleToGuests
              visibleToCleaners
            />

            <FormGroup
              name="home_info_location_attributes.nearest_supermarket"
              label="Nearest supermarket"
              visibleToGuests
              visibleToCleaners
            />

            <FormGroup
              name="home_info_location_attributes.favourite_spots"
              label="Favourite spots"
              visibleToGuests
            />

            <FormGroup
              name="home_info_location_attributes.nearest_restaurant"
              label="Nearest restaurant"
              visibleToGuests
            />
          </Fieldset>
        </HomeInfoForm>
      )}
    </Formik>
  );
};
