import { Title } from "@mantine/core";
import React from "react";

import { PartnerSelect } from "../PartnerSelect";
import { PartnerView } from "../PartnerView";

export const Payments = () => {
  return (
    <>
      <Title my="md">Partner payment demands</Title>

      <PartnerSelect />
      <PartnerView />
    </>
  );
};
